
import mixins from 'vue-typed-mixins';
import { required } from 'vuelidate/dist/validators.min';
import { mapGetters } from 'vuex';

import WithdrawData from 'Modules/Payment/components/Withdraw/Withdraw.Data.vue';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import SelectFee from 'Control/SelectFee.vue';
import AssetValue from 'UI/AssetValue.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Account from 'Entities/privatePresenter/Account';
import Balance from 'Entities/privatePresenter/Balance';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

type Data = {
    feeSize: null | string;
    previousRoute: string;
    hasError: boolean;
    isButtonDisabled: boolean;
};

type Methods = {
    performWithdraw: () => void;
    showErrorNotification: () => void;
    setFee: (data: string) => void;
};

type Computed = {
    activeAccountId: string;
    areFeesSame: boolean;
    isThemeDark: boolean;
    activeAccount: Account[];
    fees: Record<string, {
        amount: number;
        assetSymbol: string;
    }>;
    isWithdrawAvailable: {
        isValid: boolean;
        message: string;
    };
    availableBalance: number;
    feeAssetBalance: Balance | undefined;
    isXLMBalanceAvailable: boolean;
    infoText: string;
    currentUser: InternalUserResponse | undefined;
    needFees: boolean;
};

export default mixins(WithdrawData).extend<Data, Methods, Computed>({
    mixins: [WithdrawData],
    components: {
        SelectFee,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            feeSize: null,
            previousRoute: '/wallets',
            hasError: false,
            isButtonDisabled: false,
        };
    },
    validations() {
        return {
            feeSize: {
                required,
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            isThemeDark: 'isThemeDark',
        }),
        needFees() {
            return this.fees && !this.areFeesSame;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        infoText() {
            if (this.currentUser?.type === 'CORPORATE') {
                return `
                    <span>
                        Crypto withdrawals can only be sent to your company's whitelisted crypto wallets.
                        <br />
                        Withdrawals to third-party or non-whitelisted wallets aren't allowed.
                    </span>
                `;
            }
            return `
                <span>
                    Crypto withdrawals can only be sent to your personal, whitelisted crypto wallets.
                    <br />
                    Withdrawals to third-party or non-whitelisted wallets aren't allowed.
                </span>
            `;
        },
        areFeesSame() {
            if (
                !this.fees
                || !this.fees.low
                || !this.fees.medium
                || !this.fees.high
            ) {
                return false;
            }
            return this.fees.low.amount === this.fees.high.amount
                && this.fees.low.amount === this.fees.medium.amount;
        },
        activeAccount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.id === this.activeAccountId);
        },
        fees() {
            return this.withdrawUi.fees;
        },
        isWithdrawAvailable() {
            let message = 'Not enough funds';
            if (!this.fees || !this.fees.low.assetSymbol) {
                return { isValid: false, message };
            }
            const balance = this.$store.state.Balances.balances.find(({ assetSymbol, accountId, placementName, blockchainName }) => assetSymbol === this.fees.low.assetSymbol
                && accountId === this.activeAccountId
                && placementName === 'Single Broker'
                && blockchainName === this.withdrawUi.blockchain);
            if (!balance) {
                return { isValid: false, message };
            }
            if (this.fees.low.assetSymbol === this.withdrawUi.asset) {
                if (!this.feeSize) {
                    return { isValid: true, message };
                }
                const isValid = Number(this.fees[this.feeSize].amount) <= Number(this.withdrawUi.amount);
                if (!isValid) {
                    message = 'Amount must be greater than fee size';
                }
                return { isValid, message };
            }
            if (!this.feeSize) {
                return { isValid: true, message };
            }
            const isValid = Number(balance.free) >= Number(this.fees[this.feeSize].amount);
            if (!isValid) {
                message = 'Amount must be greater than fee size';
            }
            return { isValid, message };
        },
        availableBalance() {
            const balance = this.$store.state.Balances.balances.find((b) => {
                return b.assetSymbol === this.withdrawUi.asset
                    && (b.blockchainName ? b.blockchainName === this.withdrawUi.blockchain : true)
                    && b.placementName === 'Single Broker'
                    && b.accountId === this.activeAccountId;
            });
            return balance ? balance.free : 0;
        },
        feeAssetBalance() {
            const selectedFee = this.fees.low;
            return this.$store.state.Balances.balances.find(({ assetSymbol, blockchainName, placementName, accountId }) => {
                return assetSymbol === selectedFee.assetSymbol
                    && (blockchainName ? blockchainName === this.withdrawUi.blockchain : true)
                    && placementName === 'Single Broker'
                    && accountId === this.activeAccountId;
            });
        },
        // необходимо проверять, что после вывода на балансе останется хотыбы 1 XLM в случае вывода этого асета
        isXLMBalanceAvailable() {
            if (this.withdrawUi.asset !== 'XLM') {
                return true;
            }
            if (this.fees[this.feeSize]?.assetSymbol === 'XLM') {
                return (this.$store.state.Balances.balances.find((b) => {
                    return b.assetSymbol === 'XLM'
                        && b.placementName === 'Single Broker'
                        && b.accountId === this.activeAccountId
                        && b.blockchainName === this.withdrawUi.blockchain;
                })?.free ?? 0) - 1 >= Number(this.withdrawUi.amount) + Number(this.fees[this.feeSize].amount);
            }
            return (this.$store.state.Balances.balances.find((b) => {
                return b.assetSymbol === 'XLM'
                    && b.placementName === 'Single Broker'
                    && b.accountId === this.activeAccountId
                    && b.blockchainName === this.withdrawUi.blockchain;
            })?.free ?? 0) - 1 >= Number(this.withdrawUi.amount);
        },
    },
    methods: {
        async performWithdraw() {
            if (!this.isWithdrawAvailable.isValid) {
                await this.$store.dispatch('Notificator/showErrorNotification', this.isWithdrawAvailable.message);
                return;
            }
            if (!this.isXLMBalanceAvailable) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You need to keep at least 1 XLM at your balance');
                return;
            }
            this.$v.$touch();
            if (!this.$v.$invalid || !this.needFees) {
                if (this.activeAccount[0].placementLinks && this.activeAccount[0].placementLinks.find((l) => l === 'Single Broker')) {
                    this.isButtonDisabled = true;
                    await this.doWithdraw(this.activeAccountId);
                } else {
                    this.showErrorNotification();
                }
            } else {
                this.hasError = true;
            }
        },
        showErrorNotification() {
            this.$store.dispatch('Notificator/showErrorNotification', 'Your Account Is Still In Progress');
        },
        setFee(size) {
            this.feeSize = size;
            this.hasError = false;
        },
    },
    async mounted() {
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            const { withdrawUi, address, previousRoute } = this.$route.query;
            this.withdrawUi = {
                ...withdrawUi,
                address: new UserTransferAddress(address),
            };
            this.previousRoute = previousRoute;
            await this.getWithdrawFees({
                accountId: this.activeAccountId,
                amount: this.withdrawUi.amount,
            });
            await this.SET_FEE_SIZE(this.fees.medium.amount);
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    watch: {
        async feeSize() {
            try {
                let size = 0;
                switch (this.feeSize && this.fees) {
                    case 'high': {
                        size = this.fees.high.amount;
                        break;
                    }
                    case 'low': {
                        size = this.fees.low.amount;
                        break;
                    }
                    case 'medium': {
                        size = this.fees.medium.amount;
                        break;
                    }
                    default: {
                        size = this.fees.low.amount;
                        break;
                    }
                }
                await this.SET_FEE_SIZE(size);
            } catch {
                // null pointer error
            }
        },
    },
});
